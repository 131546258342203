import { Entity } from 'prismarine-entity'
import { versionToNumber } from 'renderer/viewer/common/utils'
import tracker from '@nxg-org/mineflayer-tracker'
import { loader as autoJumpPlugin } from '@nxg-org/mineflayer-auto-jump'
import { subscribeKey } from 'valtio/utils'
import { getThreeJsRendererMethods } from 'renderer/viewer/three/threeJsMethods'
import { options, watchValue } from './optionsStorage'
import { miscUiState } from './globalState'


const updateAutoJump = () => {
  if (!bot?.autoJumper) return
  const autoJump = options.autoParkour || (options.autoJump === 'auto' ? miscUiState.currentTouch && !miscUiState.usingGamepadInput : options.autoJump === 'always')
  bot.autoJumper.setOpts({
    jumpIntoWater: options.autoParkour,
    jumpOnAllEdges: options.autoParkour,
    // strictBlockCollision: true,
  })
  if (autoJump === bot.autoJumper.enabled) return
  if (autoJump) {
    bot.autoJumper.enable()
  } else {
    bot.autoJumper.disable()
  }
}
subscribeKey(options, 'autoJump', () => {
  updateAutoJump()
})
subscribeKey(options, 'autoParkour', () => {
  updateAutoJump()
})
subscribeKey(miscUiState, 'usingGamepadInput', () => {
  updateAutoJump()
})
subscribeKey(miscUiState, 'currentTouch', () => {
  updateAutoJump()
})

customEvents.on('gameLoaded', () => {
  bot.loadPlugin(tracker)
  bot.loadPlugin(autoJumpPlugin)
  updateAutoJump()

  const playerPerAnimation = {} as Record<string, string>
  const entityData = (e: Entity) => {
    if (!e.username) return
    window.debugEntityMetadata ??= {}
    window.debugEntityMetadata[e.username] = e
    if (e.type === 'player') {
      bot.tracker.trackEntity(e)
    }
  }

  let lastCall = 0
  bot.on('physicsTick', () => {
    // throttle, tps: 6
    if (Date.now() - lastCall < 166) return
    lastCall = Date.now()
    for (const [id, { tracking, info }] of Object.entries(bot.tracker.trackingData)) {
      if (!tracking) continue
      const e = bot.entities[id]
      if (!e) continue
      const speed = info.avgSpeed
      const WALKING_SPEED = 0.03
      const SPRINTING_SPEED = 0.18
      const isWalking = Math.abs(speed.x) > WALKING_SPEED || Math.abs(speed.z) > WALKING_SPEED
      const isSprinting = Math.abs(speed.x) > SPRINTING_SPEED || Math.abs(speed.z) > SPRINTING_SPEED
      const newAnimation = isWalking ? (isSprinting ? 'running' : 'walking') : 'idle'
      if (newAnimation !== playerPerAnimation[id]) {
        getThreeJsRendererMethods()?.playEntityAnimation(e.id, newAnimation)
        playerPerAnimation[id] = newAnimation
      }
    }
  })

  bot.on('entitySwingArm', (e) => {
    getThreeJsRendererMethods()?.playEntityAnimation(e.id, 'oneSwing')
  })

  bot._client.on('damage_event', (data) => {
    const { entityId, sourceTypeId: damage } = data
    getThreeJsRendererMethods()?.damageEntity(entityId, damage)
  })

  bot._client.on('entity_status', (data) => {
    if (versionToNumber(bot.version) >= versionToNumber('1.19.4')) return
    const { entityId, entityStatus } = data
    if (entityStatus === 2) {
      getThreeJsRendererMethods()?.damageEntity(entityId, entityStatus)
    }
  })

  bot.on('entityGone', (entity) => {
    bot.tracker.stopTrackingEntity(entity, true)
  })

  bot.on('entityMoved', (e) => {
    entityData(e)
  })
  bot._client.on('entity_velocity', (packet) => {
    const e = bot.entities[packet.entityId]
    if (!e) return
    entityData(e)
  })

  for (const entity of Object.values(bot.entities)) {
    if (entity !== bot.entity) {
      entityData(entity)
    }
  }

  bot.on('entitySpawn', entityData)
  bot.on('entityUpdate', entityData)
  bot.on('entityEquip', entityData)

  // Texture override from packet properties
  bot._client.on('player_info', (packet) => {
    for (const playerEntry of packet.data) {
      if (!playerEntry.player && !playerEntry.properties) continue
      let textureProperty = playerEntry.properties?.find(prop => prop?.name === 'textures')
      if (!textureProperty) {
        textureProperty = playerEntry.player?.properties?.find(prop => prop?.key === 'textures')
      }
      if (textureProperty) {
        try {
          const textureData = JSON.parse(Buffer.from(textureProperty.value, 'base64').toString())
          const skinUrl = textureData.textures?.SKIN?.url
          const capeUrl = textureData.textures?.CAPE?.url

          // Find entity with matching UUID and update skin
          let entityId = ''
          for (const [entId, entity] of Object.entries(bot.entities)) {
            if (entity.uuid === playerEntry.uuid) {
              entityId = entId
              break
            }
          }
          // even if not found, still record to cache
          getThreeJsRendererMethods()?.updatePlayerSkin(entityId, playerEntry.player?.name, playerEntry.uuid, skinUrl, capeUrl)
        } catch (err) {
          console.error('Error decoding player texture:', err)
        }
      }
    }

  })
})
